.Table-module_table--not-widget_oay8Q__Stat {
  display: grid;
  overflow-x: auto;
}

.Table-module_table--widget_xYTgm__Stat {
  flex-direction: column;
  display: flex;
}

.TableHeader-module_table-header_1B2F0__Stat {
  background-color: var(--bg-component);
  min-height: 40px;
  color: var(--text-secondary);
  text-align: center;
  justify-content: space-between;
  display: flex;
}

.TableHeader-module_table-header__team-wrapper_9J69U__Stat {
  z-index: 1;
  background-color: var(--bg-component);
  flex: 1 0 180px;
  width: 320px;
  margin-right: 10px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.TableHeader-module_table-header__team-wrapper_9J69U__Stat:after {
  z-index: 0;
  content: "";
  background-color: var(--bg-component);
  width: 5px;
  height: 100%;
  box-shadow: 4px 0 8px var(--table-shadow-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 316px;
}

.TableHeader-module_table-header__team_Ih67g__Stat {
  z-index: 1;
  background-color: var(--bg-component);
  width: 100%;
  color: var(--text-secondary);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 12px 0 12px 10px;
  overflow: hidden;
}

.TableHeader-module_table-header__metrics_NAied__Stat {
  display: flex;
}

.TableHeader-module_table-header__points_v8Y3q__Stat, .TableHeader-module_table-header__match-played_ML0yV__Stat, .TableHeader-module_table-header__wins_MuBAk__Stat, .TableHeader-module_table-header__draws_AmX7G__Stat, .TableHeader-module_table-header__losses_jXhP0__Stat, .TableHeader-module_table-header__goal-difference_3pvFG__Stat {
  width: 40px;
  color: var(--text-secondary);
  justify-content: center;
  align-items: center;
  display: flex;
}

.TableHeader-module_table-header__points_v8Y3q__Stat {
  color: var(--text-link-active);
}

.TableHeader-module_table-header__goals-for-goals-against_ZFxic__Stat {
  width: 70px;
  color: var(--text-secondary);
  justify-content: center;
  align-items: center;
  display: flex;
}

.TableHeader-module_table-header__last-five_jBsJV__Stat {
  width: 120px;
  color: var(--text-secondary);
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 660px) {
  .TableHeader-module_table-header__team_Ih67g__Stat {
    width: 180px;
  }

  .TableHeader-module_table-header__team-wrapper_9J69U__Stat {
    flex: auto;
    width: 180px;
    max-width: 180px;
  }

  .TableHeader-module_table-header__team-wrapper_9J69U__Stat:after {
    left: 176px;
  }
}

.TableLine-module_table-line_Zi9nu__Stat {
  border-top: 1px solid var(--line-line);
  text-align: center;
  height: 40px;
  color: var(--text-primary);
  background-color: var(--bg-block);
  justify-content: space-between;
  align-items: center;
}

.TableLine-module_table-line--not-widget_3f4-M__Stat {
  display: flex;
}

.TableLine-module_table-line--widget_ymD0Y__Stat {
  grid-template-columns: 1fr 80px;
  display: grid;
}

.TableLine-module_table-line--active_-OjKq__Stat {
  background-color: var(--list-bg-active);
  color: var(--text-link-active);
}

.TableLine-module_table-line--active_-OjKq__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat, .TableLine-module_table-line--active_-OjKq__Stat .TableLine-module_table-line__team_WzsC-__Stat {
  background-color: var(--list-bg-active);
}

.TableLine-module_table-line--active_-OjKq__Stat .TableLine-module_table-line__team-link_4MKxs__Stat {
  color: var(--text-link-active);
}

.TableLine-module_table-line--outcome_UT3qJ__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  content: "";
  width: 4px;
  height: 40px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.TableLine-module_table-line--secondary-main_Cw-hj__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-champions-league);
}

.TableLine-module_table-line--secondary-dark_145ci__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-champions-league-playoff);
}

.TableLine-module_table-line--yellow-light_94k3N__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-european-cup-playoff);
}

.TableLine-module_table-line--yellow-main_LOz-e__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-european-cup);
}

.TableLine-module_table-line--green-main_iU6YC__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-conference-league);
}

.TableLine-module_table-line--green-light_O6BCj__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-conference-league-playoff);
}

.TableLine-module_table-line--gray-light_oLKLS__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-relegation);
}

.TableLine-module_table-line--red-main_flOsE__Stat .TableLine-module_table-line__team-wrapper_hDy0Y__Stat:before {
  background-color: var(--icons-leagues-left);
}

.TableLine-module_table-line__team-wrapper_hDy0Y__Stat {
  z-index: 1;
  background-color: var(--bg-block);
  align-items: center;
  height: 100%;
  margin-right: 10px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.TableLine-module_table-line__team-wrapper--not-widget_v5dpK__Stat {
  flex: 1 0 180px;
  width: 320px;
}

.TableLine-module_table-line__team-wrapper--not-widget_v5dpK__Stat:after {
  z-index: 0;
  content: "";
  width: 5px;
  height: 100%;
  box-shadow: 4px 0 8px var(--table-shadow-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 316px;
}

.TableLine-module_table-line__team-wrapper--widget_1euJ8__Stat {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
}

.TableLine-module_table-line__rank_iLEHz__Stat {
  text-align: center;
  width: 20px;
  color: var(--text-tertiary);
  margin: 0 5px;
}

.TableLine-module_table-line__rank-change_HvmZU__Stat {
  align-items: center;
  width: 15px;
  margin: 0;
  display: flex;
  position: relative;
}

.TableLine-module_table-line__rank-change--up_FjDWf__Stat svg {
  fill: var(--icons-green);
}

.TableLine-module_table-line__rank-change--down_65xPj__Stat svg {
  fill: var(--icons-red);
  transform: rotate(180deg);
}

.TableLine-module_table-line__rank-change--same_n6XnH__Stat:before {
  content: "";
  background-color: var(--icons-gray);
  border-radius: 1px;
  width: 8px;
  height: 2px;
  display: block;
  position: absolute;
  top: 50%;
  left: 3px;
}

.TableLine-module_table-line__team_WzsC-__Stat {
  z-index: 1;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: var(--bg-block);
  flex: 1;
  align-items: center;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.TableLine-module_table-line__team--not-widget_UaXET__Stat {
  width: 100px;
}

.TableLine-module_table-line__team-link_4MKxs__Stat {
  max-height: 28px;
  color: var(--text-primary);
  text-overflow: ellipsis;
  white-space: pre-wrap;
  cursor: pointer;
  overflow-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.TableLine-module_table-line__team-logo-link_RPG7J__Stat {
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin: 0 8px;
  position: relative;
}

.TableLine-module_table-line__metrics_q1TLN__Stat {
  display: flex;
}

.TableLine-module_table-line__match-played_UdP7-__Stat, .TableLine-module_table-line__wins_FqN-M__Stat, .TableLine-module_table-line__draws_50856__Stat, .TableLine-module_table-line__losses_lBgXE__Stat, .TableLine-module_table-line__goal-difference_zFu5c__Stat {
  width: 40px;
  color: var(--text-primary);
}

.TableLine-module_table-line__points_gKp8g__Stat {
  width: 40px;
  color: var(--text-link-active);
}

.TableLine-module_table-line__goals-for-goals-against_zPJ8l__Stat {
  width: 70px;
  color: var(--text-primary);
}

.TableLine-module_table-line__last-five_sijxM__Stat {
  justify-content: space-between;
  align-items: center;
  width: 120px;
  display: flex;
}

@media (max-width: 660px) {
  .TableLine-module_table-line__team-wrapper--not-widget_v5dpK__Stat {
    flex: auto;
    width: 180px;
    max-width: 180px;
  }

  .TableLine-module_table-line__team-wrapper--not-widget_v5dpK__Stat:after {
    left: 176px;
  }
}

.LastFive-module_last-five_CVQQC__Stat {
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  display: flex;
}

.LastFive-module_last-five__item_xWd34__Stat {
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  display: flex;
}

.LastFive-module_last-five__item--win_tSlWj__Stat svg {
  fill: var(--icons-green);
}

.LastFive-module_last-five__item--loss_4poA5__Stat svg {
  fill: var(--icons-red);
}

.LastFive-module_last-five__item--draw_zUmtD__Stat svg {
  fill: var(--icons-gray);
}

